const helpers = {
	/**
	 * @desc This helper function a simple delay Promise based
	 * @param {number} duration
	 * @returns
	 */
	delay: (duration) => {
		return new Promise((resolve) => {
			setTimeout(() => resolve(), duration);
		});
	},

	/**
	 *
	 * @param {string} password
	 * @return boolean
	 */
	checkPassword: (password) => {
		const passwordPattern =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u0250-\u02AF\u1E00-\u1EFF\u1F00-\u1FFF!@#\$%\^&\*\(\)\-_\+=\[\]\{\}\|;:'",\.<>\/?\\`~£€§êµ])[\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u0250-\u02AF\u1E00-\u1EFF\u1F00-\u1FFFa-zA-Z\d!@#\$%\^&\*\(\)\-_\+=\[\]\{\}\|;:'",\.<>\/?\\`~£€§êµ]{8,}$/gm;

		return passwordPattern.test(password);
	},

	/**
	 * @desc This function is used when user submits a form and errors are  detected. It creates an object with error field name and a message.
	 * @param {string} field The name of the field
	 * @param {string} msg A message to show as en error
	 * @returns
	 */
	createFormError: (field, msg) => {
		return {
			entry: field,
			msg,
		};
	},

	/**
	 * @desc This helper function is usefull to show a full list of hidden html elements contained into a node list
	 * @param {NodeList} rows
	 */
	showAllRows: (rows) => {
		rows.forEach((row) => {
			row.style.display = "block";
		});
	},

	/**
	 * @desc This helper function is usefull to show a limited amout of html elements contained into a node list
	 * @param {NodeList} rows
	 * @param {number} limit
	 */
	showLimitedRows: (rows, limit) => {
		rows.forEach((row) => {
			row.style.display = "none";
		});
		for (let i = 0; i < limit; i++) {
			rows[i].style.display = "block";
		}
	},

	showAllRows: (rows) => {
		rows.forEach((row) => {
			row.style.display = "block";
		});
	},
};

/**
 * @returns HTMLElement
 */
const createLoader = () => {
	const loaderContainer = document.createElement("div");
	const loader = document.createElement("span");

	loaderContainer.classList.add("loader");
	loader.classList.add("loader__spin");

	loaderContainer.append(loader);

	return loaderContainer;
};

const runLoader = () => {
	const loader = createLoader();
	const body = document.querySelector("body");
	body.prepend(loader);
};

const removeLoader = () => {
	const loader = document.querySelector(".loader");
	if (!loader) return;

	loader.remove();
};

const convertSearchTermToLowerCase = (searchBarId, inputId) => {
	const searchBar = document.querySelector(searchBarId);

	if (!searchBar) return;

	const searchBarInput = searchBar.querySelector(inputId);

	searchBarInput.addEventListener("keyup", (e) => {
		searchBarInput.value = e.target.value.toLowerCase();
	});
};

const onSearch = (searchBarId, inputId) => {
	const searchBar = document.querySelector(searchBarId);
	if (!searchBar) return;

	const input = searchBar.querySelector(inputId);
	const button = searchBar.querySelector("button[type=submit]");

	button.addEventListener("click", () => {
		if (!input.value || input.value === "") return;
		runLoader();
	});
};

/**
 * @desc **animateCards** is a function that simulate a "state"
 * to apply and toggle a specific class name on an html element list.
 * It uses html data attribute to easilly target elements and be reusable.
 * @param {string} selector - use html data attribute to easilly find your card list and reuse this function wherever you want
 * @param {string} activeClassname - the css class name required to activate the effect
 * @param {number} defaultId - The default card which must be activated
 * @example animateCards('[data-card]="cardData"', "active", 1)
 */
const animateCards = (selector, activeClassname, defaultId) => {
	const cards = document.querySelectorAll(selector) || null;

	if (cards !== null && cards.length !== 0) {
		cards[defaultId || 0].classList.add(activeClassname);

		cards.forEach((card) => {
			card.addEventListener("mouseenter", () => {
				const activeCard = document.querySelector(`${selector}.${activeClassname}`);
				activeCard.classList.remove(activeClassname);

				if (card.classList.contains(activeClassname) === false) {
					card.classList.add(activeClassname);
				}
			});
		});
	}
};

/**
 * @desc **toggleDesignNavigation** is used on design page to allow an easy scroll
 * navigation between sections
 * @param {string} containerClassName - the navigation list container
 * @param {string} buttonClassName  - the open/close navigation button
 * @example toggleDesignNavigation("designSection__float", "designSection__toggle")
 */
const toggleDesignNavigation = (containerClassName, buttonClassName) => {
	const container = document.querySelector(`.${containerClassName}`);
	const button = document.querySelector(`.${buttonClassName}`);

	if (container && button) {
		button.addEventListener("click", () => {
			container.classList.toggle("visible");
		});
	}
};

const handleScrollTopButtonVisibility = (buttonClassName, triggerClassName) => {
	const scrollButton = document.querySelector(buttonClassName);

	if (scrollButton) {
		const header = document.querySelector(triggerClassName);

		if (header) {
			const callback = (entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						scrollButton.classList.remove("visible");
					} else {
						scrollButton.classList.add("visible");
					}
				});
			};

			const observer = new IntersectionObserver(callback);

			observer.observe(header);
		}
	}
};

/**
 * @desc **toggleTabs** is used when tabs navigations is needed
 * @param {string} tabSectionClassName as a Js Dom class selector (including ".")
 * @param {string} tabButtonClassName as a Js Dom class selector (including ".")
 * @param {string} tabElementClassName as a Js Dom class selector (including ".")
 * @param {string} activeClassName as a simple css class (without ".")
 * @example toggleTabs(".tab__section", ".tab__button", ".tab__element", "active")
 */
const toggleTabs = (
	tabSectionClassName,
	tabButtonClassName,
	tabElementClassName,
	activeClassName
) => {
	const tabSection = document.querySelector(tabSectionClassName);
	if (!tabSection) return;

	let current = 0;

	const tabButtons = tabSection.querySelectorAll(tabButtonClassName);
	const tabElements = tabSection.querySelectorAll(tabElementClassName);

	if (tabButtons[0].tagName === "BUTTON" && tabButtons[0].dataset.category === undefined) {
		tabButtons.forEach((button, index) => {
			button.addEventListener("click", () => {
				tabButtons[current].classList.remove(activeClassName);
				tabElements[current].classList.remove(activeClassName);

				current = index;

				tabButtons[current].classList.add(activeClassName);
				tabElements[current].classList.add(activeClassName);
			});
		});

		tabButtons[current].classList.add(activeClassName);
		tabElements[current].classList.add(activeClassName);
	}
};

const navigateWithButton = (buttonDataType) => {
	if (!window.location.pathname.includes("/search")) return;

	const buttons = document.querySelectorAll(".nav-button");

	if (!buttons.length) return;

	buttons.forEach((button) => {
		button.addEventListener("click", () => {
			if (button.dataset[buttonDataType] && button.tagName === "BUTTON") {
				runLoader();

				let url = "";
				let queryString = window.location.pathname;
				let request = `${buttonDataType}:${button.dataset[buttonDataType]}`;

				if (queryString.search(`${buttonDataType}:`) !== -1) {
					let pattern = new RegExp(`${buttonDataType}:[a-z].{1,}`, "g");
					let newQuery = queryString.replace(pattern, request);

					url = newQuery;
				} else {
					queryString += `%20${request}`;
					url = queryString;
				}

				window.location = url;
			}
		});
	});
};

/**
 *
 * @param {string} paginationLinkId
 */
const addLoaderOnPaginate = (paginationLinkId) => {
	const links = document.querySelectorAll(paginationLinkId);

	if (!links.length) return;

	links.forEach((link) => {
		link.addEventListener("click", () => runLoader());
	});
};

/**
 * @param {string} formId
 */
const initSubscriptionForm = (formId) => {
	const form = document.querySelector(formId);

	if (!form) return;

	const plan = {
		price: +form.dataset.price,
		name: form.dataset.plan,
		default: +form.dataset.default,
		min: +form.dataset.min,
		max: +form.dataset.max,
		step: +form.dataset.step,
	};

	const widget = {
		btnIncrement: form.querySelector("#increment"),
		btnDecrement: form.querySelector("#decrement"),
		quantity: form.querySelector("#quantityWidget-value"),
		plan: form.querySelector("#quantityWidget-plan"),
		price: form.querySelector("#quantityWidget-price"),
		subTotal: form.querySelector("#quantityWidget-subtotal"),
	};

	const inputs = {
		plan: form.querySelector("#plan-name"),
		price: form.querySelector("#plan-price"),
		quantity: form.querySelector("#plan-quantity"),
		subtotal: form.querySelector("#plan-subtotal"),
		vat: form.querySelector("#plan-vat"),
		total: form.querySelector("#plan-total"),
	};

	const state = {
		price: plan.price,
		name: plan.name,
		quantity: plan.default,

		vat: function () {
			return +(this.price * this.quantity * 0.2).toFixed(2);
		},
		vatStr: function () {
			return parseFloat(this.vat()).toLocaleString(undefined, { minimumFractionDigits: 2 });
		},
		subtotal: function () {
			return this.price * this.quantity;
		},
		subTotalStr: function () {
			return this.subtotal(2).toLocaleString(undefined, { minimumFractionDigits: 2 });
		},
		total: function () {
			return +this.subtotal() + +this.vat();
		},
		totalStr: function () {
			return this.total().toLocaleString(undefined, { minimumFractionDigits: 2 });
		},
	};

	widget.plan.innerText = plan.name;
	widget.price.innerText = `${plan.price.toFixed(2)}€`;
	widget.subTotal.innerText = `${state.subtotal().toFixed(2)}€`;

	inputs.plan.value = plan.name;
	inputs.price.value = plan.price;
	inputs.quantity.value = plan.default;
	inputs.subtotal.value = state.subtotal();
	inputs.vat.value = state.vat();
	inputs.total.value = state.total();

	const setActiveButton = (button, limit, current) => {
		if (limit === current) {
			button.setAttribute("disabled", true);
		} else {
			button.removeAttribute("disabled");
		}
	};

	const checkButtons = () => {
		setActiveButton(widget.btnDecrement, plan.min, state.quantity);
		setActiveButton(widget.btnIncrement, plan.max, state.quantity);
	};

	checkButtons();

	const updateValues = () => {
		widget.quantity.innerText = state.quantity;
		widget.subTotal.innerText = `${state.subTotalStr()}€`;

		inputs.quantity.value = +state.quantity;
		inputs.subtotal.value = +state.subtotal();
		inputs.vat.value = +state.vat();
		inputs.total.value = +state.total();
	};

	widget.btnIncrement.addEventListener("click", () => {
		if (state.quantity === plan.max) return;
		state.quantity += plan.step;
		updateValues();
		checkButtons();
	});
	widget.btnDecrement.addEventListener("click", () => {
		if (state.quantity === plan.min) return;
		state.quantity -= plan.step;
		updateValues();
		checkButtons();
	});
};

const setActiveNavigationLink = () => {
	const navLinks = document.querySelectorAll(".header__navLink");

	if (navLinks.length) {
		navLinks.forEach((link) => {
			if (link.attributes.href.value === window.location.pathname) {
				link.classList.add("active");
			}

			if (link.attributes.href.value !== "/") {
				const url = link.attributes.href.value;

				if (window.location.pathname.includes(url)) {
					link.classList.add("active");
				}
			}
		});
	}
};

/**
 * @desc A simple function to copy input value to clipbard
 * @param {string} elementId - the input field id including the '#' id symbol
 */
const copyValueToClipboard = (elementId, message) => {
	const element = document.querySelector(elementId);
	if (element) {
		element.select();
		element.setSelectionRange(0, 99999);
		navigator.clipboard.writeText(element.value);
		alert(message);
	}
};

/**
 * @desc
 * @param {string} listContainer - the cards list's css class `.classname`
 * @param {string} listItemClass
 * @param {string} buttonClass
 * @param {string} tabClass
 * @param {number} defaultTabIndex
 */
const setCardResultTabNavigation = (
	listContainer,
	listItemClass,
	buttonClass,
	tabClass,
	defaultTabIndex = 0
) => {
	const cardList = document.querySelector(listContainer);

	if (!cardList) return;

	const cards = cardList.querySelectorAll(listItemClass);

	const resetElements = (htmlElementList) => {
		htmlElementList.forEach((element) => element.classList.remove("active"));
	};

	cards.forEach((card) => {
		const buttons = card.querySelectorAll(buttonClass);
		const tabs = card.querySelectorAll(tabClass);

		buttons[defaultTabIndex].classList.add("active");
		tabs[defaultTabIndex].classList.add("active");

		buttons.forEach((button, index) => {
			button.addEventListener("click", () => {
				const current = button.dataset.trigger;

				resetElements(buttons);
				resetElements(tabs);
				button.classList.add("active");
				// tabs[index].classList.add("active");
				const currentTab = card.querySelector(`[data-target=${current}]`);
				currentTab.classList.add("active");
			});
		});
	});
};

/**
 * @desc a simple function to manage filters visibility behavior
 * @param {string} filterSectionClassname
 */
const setSearchFilterHeight = (filterSectionClassname) => {
	const filters = document.querySelectorAll(filterSectionClassname);

	if (!filters) return;

	filters.forEach((filter) => {
		const button = filter.querySelector(".showMore");
		const rows = filter.querySelectorAll(".formGroup");
		const min = filter.dataset.min || 11;

		if (!rows) return;

		if (button) {
			helpers.showLimitedRows(rows, min);
			button.addEventListener("click", () => {
				filter.classList.toggle("folded");

				if (filter.classList.contains("folded")) {
					button.innerText = "Show more";

					helpers.showLimitedRows(rows, min);

					const filterPosition = filter.getBoundingClientRect().top;
					const windowPosition = window.pageYOffset;
					const scrollPosition = filterPosition - windowPosition;

					window.scrollTo(0, scrollPosition);
				} else {
					button.innerText = "Show less";
					helpers.showAllRows(rows);
				}
			});
		} else {
			helpers.showAllRows(rows);
		}
	});
};

const toggleSidebarVisibility = () => {
	const sidebar = document.querySelector(".sidebar");

	if (sidebar) {
		sidebar.classList.toggle("visible");
	}
};

/**
 *
 * @param {string} containerId
 */
const makeTableScrollableHorizontally = (containerId) => {
	const container = document.querySelector(containerId);

	if (!container) return;

	const tables = container.querySelectorAll("table");

	tables.forEach((table) => {
		const wrapper = document.createElement("div");
		const newTable = table.cloneNode(true);
		wrapper.classList.add("table__wrapper");
		wrapper.append(newTable);

		table.before(wrapper);
		table.remove();
	});
};

const runLoaderOnLinkSearch = () => {
	const container = document.querySelector(".sectionSearchResult");

	if (!container) return;
	let buttons = [];

	const tabButtons = container.querySelectorAll("a.tab__button");
	const tagButtons = container.querySelectorAll("a.textTag");
	const accordionButtons = container.querySelectorAll("a.tab__accordionLink");

	buttons = [...tabButtons, ...tagButtons, ...accordionButtons];
	buttons.forEach((button) => {
		button.addEventListener("click", () => {
			if (!button.href.includes("#")) {
				runLoader();
			}
		});
	});
};

const resetSearchFilter = () => {
	const container = document.querySelector(".sectionSearchResult");
	if (!container) return;

	const form = document.querySelector("#searchForm");

	if (!form) return;

	const checkboxes = form.querySelectorAll("input[type=checkbox]");
	const resetButton = form.querySelector("button[type=reset]");
	const orderElement = form.querySelector(".searchResults__select");

	resetButton.addEventListener("click", () => {
		checkboxes.forEach((checkbox) => {
			checkbox.checked = false;
		});

		if (orderElement) {
			orderElement.selectedIndex = 0;
		}

		form.submit();
	});
};

const initializeNavigationWithTabs = (
	triggers,
	targets,
	activeClassname = "active",
	initialIndex = 0
) => {
	if (!triggers || !targets || !activeClassname) {
		throw new Error("initializeNavigationWithTabs function has missing arguments");
	}

	triggers[initialIndex].classList.add(activeClassname);
	targets[initialIndex].classList.add(activeClassname);
};

/**
 * @desc allow to navigate into a page using tabs and urls
 * @param {{
 * 	page: string
 * 	triggerAttr: string
 * 	targetAttr: string
 * 	activeClassname: string
 * }} config - an object containing the page where navigation should be
 * effective, the triggers and targets data-attribute and the activeClassname which is optional (default is : `active`
 * @example const config = {
 * 	page: "pricing",
 * 	triggerAttr: "data-tab-trigger",
 * 	targetAttr: "data-tab-target",
 * };
 *
 * handleNavigationWithTabs(config);
 */
const handleNavigationWithTabs = (config) => {
	const { page, triggerAttr, targetAttr, activeClassname = "active" } = config;
	const { hash, origin, pathname } = window.location;

	if (!pathname.includes(page)) {
		return;
	}

	const triggers = document.querySelectorAll(`[${triggerAttr}]`);
	const tabs = document.querySelectorAll(`[${targetAttr}]`);

	triggers.forEach((trigger) => {
		trigger.addEventListener("click", () => {
			const anchor = trigger.getAttribute(triggerAttr);
			const url = `${origin}/${page}#${anchor}`;

			window.location.assign(url);
			window.location.reload();
		});
	});

	if (!hash) {
		initializeNavigationWithTabs(triggers, tabs, activeClassname);
	} else {
		const currentTrigger = Array.from(triggers).find(
			(trigger) => trigger.getAttribute(triggerAttr) === hash.substring(1)
		);
		const currentTarget = Array.from(tabs).find(
			(target) => target.getAttribute(targetAttr) === hash.substring(1)
		);

		if (!currentTrigger || !currentTarget) {
			initializeNavigationWithTabs(triggers, tabs, activeClassname);

			console.error("No tab or tab trigger matching with the current anchor");
		}

		currentTrigger.classList.add(activeClassname);
		currentTarget.classList.add(activeClassname);
	}
};

// ---------------------
// doc navbar
// ---------------------
const handleDocSidebar = (sidebarClassName) => {
	const sidebar = document.querySelector(sidebarClassName);

	if (!sidebar) return;

	const url = window.location.pathname;
	const urlEndpoint = url.split("/").pop().replaceAll(" ", "-").toLowerCase();

	const details = sidebar.querySelectorAll("details");
	const links = sidebar.querySelectorAll("a");
	const lists = sidebar.querySelectorAll("ul:not(.inner)>li");

	details.forEach((detail) => {
		const title = detail
			.querySelector("summary>span")
			.innerHTML.replace(" ", "-")
			.toLowerCase();
		if (url.includes(title)) {
			detail.open = true;
		}
	});

	links.forEach((link) => {
		if (urlEndpoint === link.innerHTML.replaceAll(" ", "-").toLowerCase()) {
			link.classList.add("active");
		}
	});

	lists.forEach((list) => {
		const detail = list.querySelector("details");
		if (detail.open === true) {
			list.classList.add("active");
		}
	});
};

(() => {
	(function () {
		window.onpageshow = function (event) {
			if (event.persisted) {
				window.location.reload();
			}
		};
	})();
	// -----------------------------------------
	// NAVBAR LINKS
	// -----------------------------------------
	setActiveNavigationLink();

	// -----------------------------------------
	// PRICING NAVIGATION
	// -----------------------------------------
	const pricingTabConfig = {
		page: "pricing",
		triggerAttr: "data-tab-trigger",
		targetAttr: "data-tab-target",
	};
	handleNavigationWithTabs(pricingTabConfig);

	// -----------------------------------------
	// CARDS ANIMATION
	// -----------------------------------------
	animateCards('[data-card="cardData"]', "active", 1);
	animateCards('[data-card="cardDiscover"]', "active", 0);
	animateCards('[data-card="cardRansomware"]', "active", 0);

	// -----------------------------------------
	// MISCELLANEOUS BEHAVIOR
	// -----------------------------------------
	toggleDesignNavigation("designSection__float", "designSection__toggle");
	handleScrollTopButtonVisibility(".scrollTopButton", ".scrollTopTrigger");

	// -----------------------------------------
	// SUBSCRIBTION FUNNEL
	// -----------------------------------------
	initSubscriptionForm("#subscriptionForm");

	// -----------------------------------------
	// SEARCH RESULT PAGE
	// -----------------------------------------
	setCardResultTabNavigation(
		".cardResult__list",
		".cardResult__listItem",
		".cardResult__tabButton",
		".cardResult__tab"
	);
	setSearchFilterHeight(".searchFilter__section");
	navigateWithButton("category");
	navigateWithButton("tag");
	addLoaderOnPaginate(".pagination__link");
	onSearch(".searchBar", ".searchBar__input");
	runLoaderOnLinkSearch();
	resetSearchFilter();

	// -----------------------------------------
	// DOC PAGES
	// -----------------------------------------
	makeTableScrollableHorizontally(".doc__container");
	handleDocSidebar(".sidebar");
})();
